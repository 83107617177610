export class SlickSlider {
    SlickSliderInit(){
        const $ele = $(this.selector);
        if($ele.length > 0){
            const args = this.args;
            $ele.slick(args);
        }
    }
    constructor({selector, args} = {}){
        this.selector = selector;
        this.args = args;
    }
}  