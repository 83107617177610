// import $ from 'jquery';
// window.jQuery = $;
// window.$ = $;
// import './js/navigation.js'; 
// import Foundation from 'foundation-sites';

// import { Foundation } from './theme'; // currently not using



// var WebFont = require('webfontloader'); // Page base
// import { Foundation } from 'foundation-sites/js/foundation.core';
// import * as CoreUtils from 'foundation-sites/js/foundation.core.utils'; // not using - needed for utils ??????
// import { Box } from 'foundation-sites/js/foundation.util.box'; // Will return an object that contains the dimensions of element
// import { onImagesLoaded } from 'foundation-sites/js/foundation.util.imageLoader'; // This will execute your callback function after all the images in your jQuery collection have loaded.
// import { Keyboard } from 'foundation-sites/js/foundation.util.keyboard'; // Methods for keyboard interaction 
// import { MediaQuery } from 'foundation-sites/js/foundation.util.mediaQuery'; // The media query library used by Foundation has two publicly accessible functions and two properties
// import { Motion, Move } from 'foundation-sites/js/foundation.util.motion'; // Two utils
// import { Nest } from 'foundation-sites/js/foundation.util.nest'; ??????
// import { Timer } from 'foundation-sites/js/foundation.util.timer'; // Similar to setInterval, except you can pause and resume where you left off
// import { Touch } from 'foundation-sites/js/foundation.util.touch'; // Allows you to add swipe* and pseudo-drag events to elements
// import { Triggers } from 'foundation-sites/js/foundation.util.triggers'; // Provides a number of event listeners and triggers your script can hook into
// import { Abide } from 'foundation-sites/js/foundation.abide';
// import { Accordion } from 'foundation-sites/js/foundation.accordion';
// import { AccordionMenu } from 'foundation-sites/js/foundation.accordionMenu'; // Page base
// import { Drilldown } from 'foundation-sites/js/foundation.drilldown'; // Page base
// import { Dropdown } from 'foundation-sites/js/foundation.dropdown';
// import { DropdownMenu } from 'foundation-sites/js/foundation.dropdownMenu'; // Page base
// import { Equalizer } from 'foundation-sites/js/foundation.equalizer'; 
// import { Interchange } from 'foundation-sites/js/foundation.interchange';
// import { Magellan } from 'foundation-sites/js/foundation.magellan';
// import { OffCanvas } from 'foundation-sites/js/foundation.offcanvas'; // Page base
// import { Orbit } from 'foundation-sites/js/foundation.orbit';
// import { ResponsiveMenu } from 'foundation-sites/js/foundation.responsiveMenu'; // Page base
// import { ResponsiveToggle } from 'foundation-sites/js/foundation.responsiveToggle'; // _jwp_nav-title-bar.php
// import { Reveal } from 'foundation-sites/js/foundation.reveal';
// import { Slider } from 'foundation-sites/js/foundation.slider';
// import { SmoothScroll } from 'foundation-sites/js/foundation.smoothScroll';
// import { Sticky } from 'foundation-sites/js/foundation.sticky';
// import { Tabs } from 'foundation-sites/js/foundation.tabs';
// import { Toggler } from 'foundation-sites/js/foundation.toggler';
// import { Tooltip } from 'foundation-sites/js/foundation.tooltip';
// import { ResponsiveAccordionTabs } from 'foundation-sites/js/foundation.responsiveAccordionTabs';

// Foundation.addToJquery($);
 
// Add Foundation Utils to Foundation global namespace for backwards
// compatibility.

// *** Not using - needed for utils ??????
// Foundation.rtl = CoreUtils.rtl;
// Foundation.GetYoDigits = CoreUtils.GetYoDigits;
// Foundation.transitionend = CoreUtils.transitionend;
// Foundation.RegExpEscape = CoreUtils.RegExpEscape;
// Foundation.onLoad = CoreUtils.onLoad;

// *** Not using - needed for utils ??????
// Foundation.Box = Box;
// Foundation.onImagesLoaded = onImagesLoaded;
// Foundation.Keyboard = Keyboard;
// Foundation.MediaQuery = MediaQuery;
// Foundation.Motion = Motion;
// Foundation.Move = Move;
// Foundation.Nest = Nest;
// Foundation.Timer = Timer;

// Touch and Triggers previously were almost purely sede effect driven, 
// so no need to add it to Foundation, just init them.

// *** Not using - needed for utils ??????
// Touch.init($);
// Triggers.init($, Foundation);
// MediaQuery._init();

// Foundation.plugin(Abide, 'Abide');
// Foundation.plugin(Accordion, 'Accordion');
// Foundation.plugin(AccordionMenu, 'AccordionMenu'); // Page base
// Foundation.plugin(Drilldown, 'Drilldown'); // Page base
// Foundation.plugin(Dropdown, 'Dropdown');
// Foundation.plugin(DropdownMenu, 'DropdownMenu'); // Page base
// Foundation.plugin(Equalizer, 'Equalizer');
// Foundation.plugin(Interchange, 'Interchange');
// Foundation.plugin(Magellan, 'Magellan');
// Foundation.plugin(OffCanvas, 'OffCanvas'); // Page base
// Foundation.plugin(Orbit, 'Orbit');
// Foundation.plugin(ResponsiveMenu, 'ResponsiveMenu'); // Page base
// Foundation.plugin(ResponsiveToggle, 'ResponsiveToggle');
// Foundation.plugin(Reveal, 'Reveal');
// Foundation.plugin(Slider, 'Slider');
// Foundation.plugin(SmoothScroll, 'SmoothScroll');
// Foundation.plugin(Sticky, 'Sticky');
// Foundation.plugin(Tabs, 'Tabs');
// Foundation.plugin(Toggler, 'Toggler');
// Foundation.plugin(Tooltip, 'Tooltip');
// Foundation.plugin(ResponsiveAccordionTabs, 'ResponsiveAccordionTabs');

// export { Foundation };


// import {
//     initializer
// } from './js/foundation';
// require('waypoints/lib/noframework.waypoints.min');
// import 'slick-carousel';
// import 'slick-carousel/slick/slick.css';
// import './styles/critical.scss';
import './page-company.scss';
  
import { SlickSlider } from './js/js-parts/js-slick-slider.js';

// initializer.key_initFoundation();
// jQuery(document).foundation();
// cannot apply update. Need to do a full reload - only accept if full relod is not needed
// if(module && module.hot){
//     module.hot.accept();
// }    
// ****************************** // 
// ****** WRAPPER  ****** // 
// ****************************** // 
// IIFE - Immediately Invoked Function Expression
(function (bptcode) {

    // The global jQuery object is passed as a parameter 
    bptcode(window.jQuery, window, document);

}(function ($, window, document) {

    // The $ is now locally scoped

    $(document).ready(function () {
       
          // *** CODE HERE *** //
        //   $(document).foundation();

        const SlickSliderPL = new SlickSlider({
            selector: '.posts-listing__slick', 
            args: {
            infinite: true,
            speed: 300,
            arrows: false,
            dots: true,
            autoplay: false,
            autoplaySpeed: 5000,
            mobileFirst: true,
            adaptiveHeight: true,
            responsive: [{
                    breakpoint: 1,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    },
                    breakpoint: 1024,
                    settings: 'unslick'
                }
            ]
        }});
        SlickSliderPL.SlickSliderInit();

        const SlickSliderRCS = new SlickSlider({
            selector: '.featured-work__slick', 
            args: {
            infinite: true,
            speed: 300,
            arrows: false,
            dots: true,
            autoplay: false,
            autoplaySpeed: 5000,
            mobileFirst: true,
            adaptiveHeight: false,
            responsive: [{
                    breakpoint: 1,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        }});
        SlickSliderRCS.SlickSliderInit();
    
        // *** END CODE *** //
        // ****************************** // 
        // ****** END WRAPPER  ****** // 
        // ****************************** //
    });
}));
